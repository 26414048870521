import {inject, Injectable} from "@angular/core";
import {DOCUMENT} from "@angular/common";
import {ConfigService} from "@scpc/modules/common/services/config.service";

@Injectable({providedIn: 'root'})
export class ChatService {

  private document: Document = inject(DOCUMENT);
  private configService: ConfigService = inject(ConfigService);

  public isEnabled(): boolean {
    return !!this.configService.supportChats?.find(c => c.__component === 'support-chats.zendesk');
  }

  public open(): void {
    if (this.isEnabled()) {
      (this.document.querySelector(".scp-header__chats") as HTMLButtonElement)?.click();
    }
  }

}
